export default {
  data() {
    return {
      pallete: {
        $primary_bg_color: '#fff',
        $primary_text_color: '#2a2a2a',
        $secondary_text_color: '#999',
        $dark_primary_text_color: '#fff',
        $active_color: '#4285f4',
        $safe_color: '#1DC1AC',
        $dangerous_color: '#ff5252',
        $deactive_color: '#999',
        $overlay_color: 'rgba(0, 0, 0, 0.7)',
      },
      utilTrackingEventPrams: null
    }
  },
  methods: {
    setUtilTrackingEventPrams(utilTrackingEventPrams) {
      this.utilTrackingEventPrams = utilTrackingEventPrams
    },
    sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time))
    }
  }
}
