import { BUILT_GIT_COMMIT, BUILT_TIME } from '@/built-info'
import { mixpanelToken } from '@/components/utils/config'
import mixpanel from 'mixpanel-browser'
import db from './firebase'
mixpanel.init(mixpanelToken)
// dev-v1: 6d47c2b5c863d2dc435d6cc17799f5d4

const sendTrackingEvent = (message, obj) => {
  try {
    let logObj = obj ? obj : {}
    if (BUILT_TIME > 0) {
      logObj = {
        ...logObj,
        siteBuiltAt: BUILT_TIME,
        siteBuiltGitCommit: BUILT_GIT_COMMIT
      }
    }
    mixpanel.track(message, logObj)
    window.gtag('event', message, logObj)
    // TO DO: remove tracking logs in firestore
    db.collection('TRACKING_LOG').add({
      log: logObj,
      type: message,
      createdAt: new Date(),
      createdBy: obj.uid ? obj.uid : 'no_user'
    })
  } catch (err) {
    console.error(`[mixpanel] track ${obj}`, JSON.stringify(err, null, 2))
  }
}

const identifyTrackedUser = (uid) => {
  try {
    mixpanel.identify(uid)
    // console.log('identifyTrackedUser', uid)
  } catch (err) {
    console.error(`[mixpanel] could not identify ${uid}`, JSON.stringify(err, null, 2))
  }
}

const signUpForTracking = (user) => {
  try {
    mixpanel.people.set({
      ...user,
      $name: user.name,
      $email: user.email
    })
    mixpanel.identify(user.uid)
    // console.log('signUpForTracking', user)
  } catch (err) {
    console.error(`[mixpanel] could not set people ${user}`, JSON.stringify(err, null, 2))
  }
}

export { sendTrackingEvent, signUpForTracking, identifyTrackedUser }

// sendTrackingEvent(
//   trackingMessage,
//   {
//     uid: this.uid,
//     eventId: this.$route.params.eventId,
//     title: this.event.title,
//     level: this.event.level,
//     startTime: this.event.startTime.toDate(),
//     endTime: this.event.endTime.toDate(),
//     trainer: this.event.trainer,
//     room: this.event.roomURL,
//     path: this.$route.name
//   },
//   this.uid
// )
