<template lang="pug">
v-app
  v-main
    router-view
    Message
</template>

<script>
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
import {
  sendTrackingEvent,
  signUpForTracking,
  identifyTrackedUser
} from '@/components/utils/eventTracker'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
const teamStore = createNamespacedHelpers('team')
import Message from '@/components/shared/Message'

// comment for deploy
export default {
  name: 'App',
  components: {
    Message
  },
  async mounted() {
    this.$root.$on('sendTrackingEventByClick', this.sendTrackingEventByClick)

    // let script = document.createElement('script')
    // script.type = 'text/javascript'
    // script.src = 'https://apis.google.com/js/client:plusone.js'
    // // Once the Google API Client is loaded, you can run your code
    // script.onload = this.gapiOnload
    // // Add to the document
    // document.getElementsByTagName('head')[0].appendChild(script)
    // setTimeout(this.gapiOnload, 1000)
  },
  data() {
    return {
      utilTrackingEventPrams: null
    }
  },
  computed: {
    ...mapStateAuth(['uid', 'isAnonymous']),
    ...teamStore.mapState(['team'])
  },
  watch: {
    // send tracking events
    async $route(to, from) {
      let user
      if (this.uid && !this.isAnonymous) {
        user = await database.userCollection().findById(this.uid)
        signUpForTracking(user) // TO DO: check user exists in mixpannel
        identifyTrackedUser(this.uid)
      }

      const utilTrackingEventPrams = {
        uid: this.uid ? this.uid : '',
        pageName: to.name,
        page: to.path,
        plan: this.team && this.uid ? 'team' : 'basic',
        userType: this.uid && !this.isAnonymous ? 'registered' : 'anonymous',
        teamId: this.team && this.uid ? this.team.id : ''
      }
      this.setUtilTrackingEventPrams(utilTrackingEventPrams)

      let trackingLog = {
        fromPage: from && from.path ? from.path : '',
        fromPathName: from && from.name ? from.name : '',
        ...utilTrackingEventPrams
      }
      if (this.$route.params.meetingId) trackingLog.meetingId = this.$route.params.meetingId
      sendTrackingEvent('page_view', trackingLog)
    }
  },
  methods: {
    async sendTrackingEventByClick ({ message, option }) {
      if (!this.utilTrackingEventPrams) await this.setUtilTrackingEvent
      const trackingLog = {
        ...this.utilTrackingEventPrams,
        ...option
      }
      console.log(message, { ...trackingLog })
      sendTrackingEvent(message, trackingLog)
    },
    async setUtilTrackingEvent () {
      let user
      if (this.uid && !this.isAnonymous) {
        user = await database.userCollection().findById(this.uid)
        signUpForTracking(user) // TO DO: check user exists in mixpannel
        identifyTrackedUser(this.uid)
      }

      const utilTrackingEventPrams = {
        uid: this.uid ? this.uid : '',
        pageName: this.$route.params.name,
        page: this.$route.params.path,
        plan: this.team && this.uid ? 'team' : 'basic',
        userType: this.uid && !this.isAnonymous ? 'registered' : 'anonymous',
        teamId: this.team && this.uid ? this.team.id : ''
      }
      this.setUtilTrackingEventPrams(utilTrackingEventPrams)
    },
    // async gapiOnload () {
    //   // client id
    //   // 49690083804-kqmuqucgft2fn8skb98j6nbgvv5h96e6.apps.googleusercontent.com
    //   // client secret
    //   // MC2VWn4RUG-MArzwpruu6N7h
    //   await this.sleepByPromise(800)
    //   const scopes = [
    //     'email',
    //     'profile',
    //     'https://www.googleapis.com/auth/calendar',
    //     'https://www.google.com/m8/feeds/',
    //     'https://www.googleapis.com/auth/contacts.readonly'
    //   ]
    //   // Initialize the Google API Client with the config object
    //   gapi.client.init({
    //     apiKey: config.apiKey,
    //     clientId: clientId,
    //     discoveryDocs: discoveryDocs,
    //     scope: scopes.join(' '),
    //   })
    //   .then(function() {
    //     // Loading is finished, so start the app
    //     // debugger
    //     // Make sure the Google API Client is properly signed in
    //     if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
    //       // startApp(user)
    //       console.log('gapi has been loaded')
    //     } else {
    //       // firebase.auth().signOut() // Something went wrong, sign out
    //     }
    //  })
    //  .catch((e) => {
    //     console.log('gapi error:', e.error)
    //  })
    // },
    sleepByPromise(time) {
      return new Promise((resolve) => setTimeout(resolve, time))
    }
  }
}
</script>

<style lang="scss">
@import './plugins/meltline.css';

html,
body {
  margin: 0;
}
</style>
