const config = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID
}

const api = process.env.VUE_APP_API_ORIGIN
// const api = process.env.VUE_APP_API_LOCAL_ORIGIN
const domain = process.env.VUE_APP_DOMAIN
const clientId = process.env.VUE_APP_OAUTH_CLIENT_ID

const discoveryDocs = [
  'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
  'https://www.googleapis.com/discovery/v1/apis/people/v1/rest'
  // 'https://people.googleapis.com/$discovery/rest?version=v1',
]

const scopes = [
  'email',
  'profile',
  'https://www.googleapis.com/auth/calendar',
  // 'https://www.google.com/m8/feeds/',
  'https://www.googleapis.com/auth/contacts.readonly'
]

const mixpanelToken = process.env.VUE_APP_MIXPANEL_TOKEN

// export default config
export { config, api, domain, clientId, discoveryDocs, mixpanelToken, scopes }
