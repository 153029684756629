import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as auth from './auth'
import * as planSettings from './plan-settings'
import * as team from './team'
import * as tmp from './tmp'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: {
      namespaced: true,
      ...auth
    },
    tmp: {
      namespaced: true,
      ...tmp
    },
    planSettings: {
      namespaced: true,
      ...planSettings
    },
    team: {
      namespaced: true,
      ...team
    },
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })]
})
