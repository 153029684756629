import database from '@/database'

// eslint-disable-next-line no-unused-vars

export const state = () => ({
  team: null
})

export const mutations = {
  updateTeam(state, value) {
    state.team = value
  },
  clearTeam(state) {
    state.team = null
  }
}

export const actions = {
  // eslint-disable-next-line no-unused-vars
  loadTeamByUid({ dispatch, commit }, uid) {
    // eslint-disable-next-line no-unused-vars
    return database
      .teamCollection()
      .findActiveTeamByOwnerId(uid)
      .then((team) => team || database.teamCollection().findActiveTeamByMemberId(uid))
      .then((team) => commit('updateTeam', team))
  }
}
