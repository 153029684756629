<template lang="pug">
  v-touch(v-on:panup="panup")
    transition(name="slide-down")
      div(v-if="showMessage" @click="onMessage").wrap-message.f.fc
        div.message-content.px12.py12.f.fm
          v-icon(color="#fff" size="22px").mr4 {{icon}}
          span.fz14.line-clamp-1 {{message}}
</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
$message_height: 48px;

.wrap-message {
  position: fixed;
  width: 100%;
  top: 16px;
  top: calc(16px + constant(safe-area-inset-top));
  top: calc(16px + env(safe-area-inset-top));
  z-index: 10001;
  .message-content {
    // width: $base_width_percent;
    max-width: $base_max_width_px;
    height: $message_height;
    background: $dark_primary_bg_color;
    filter: $drop_shadow;
    border-radius: 9px;
    span {
      color: $dark_primary_text_color;
    }
  }
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.6s $ease_out_expo;
  transform: translateY(0px);
}
.slide-down-enter,
.slide-down-leave-to {
  transform: translateY(calc(-#{$message_height} - 32px));
  transform: translateY(calc(-#{$message_height} - 32px - constant(safe-area-inset-top)));
  transform: translateY(calc(-#{$message_height} - 32px - env(safe-area-inset-top)));
}
.slide-down-enter-to,
.slide-down-leave {
  transform: translateY(0px);
}
</style>

<script>
import Mixin from '@/components/mixin'

export default {
  mixins: [Mixin],
  data () {
    return {
      showMessage: false,
      message: null,
      icon: null,
      to: null
    }
  },
  created () {
    // this.setGlobalComponentMethods('showSharedMessage', this.showSharedMessage)
    window.showSharedMessage = this.showSharedMessage
    this.$root.$on('showSharedMessage', this.showSharedMessage)
  },
  methods: {
    async showSharedMessage (data) {
      this.showMessage = true
      this.message = data.message
      this.icon = data.icon
      this.to = data.to
      await this.sleep(3600)
      this.showMessage = false
    },
    panup (e) {
      if (e.velocityY < 0) this.showMessage = false
    },
    onMessage () {
      if (this.to) {
        this.$router.push(this.to)
        this.showMessage = false
      }
    }
  }
}
</script>
