import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/sign-up'
    },
    {
      path: '/reports',
      redirect: '/sign-in'
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home')
    },
    {
      path: '/home/:filter',
      name: 'home-with-filter',
      props: true,
      component: () => import('@/views/Home')
    },
    // {
    //   path: '/home/:tab',
    //   name: 'home-with-tab',
    //   props: true,
    //   component: () => import('@/views/Home')
    // },
    {
      path: '/calendar/:defaultMembers?',
      name: 'calendar',
      props: true,
      component: () => import('@/views/Calendar')
    },
    {
      path: '/calendar/meeting/:meetingId',
      name: 'calendar-meeting',
      props: true,
      component: () => import('@/views/Meeting')
    },
    {
      path: '/settings/master-slots-condition',
      name: 'master-slots-condition',
      component: () => import('@/views/SetMasterSlotsCondition')
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/Settings')
    },
    {
      path: '/create-meeting/:meetingIdToTakeOver?',
      name: 'create-meeting',
      props: true,
      component: () => import('@/views/CreateMeeting')
    },
    {
      path: '/create-meeting/get-token/:meetingId',
      name: 'create-meeting-get-token',
      props: true,
      component: () => import('@/views/CreateMeetingGetToken')
    },
    {
      path: '/create-meeting/send-attendee-events/:meetingId',
      name: 'create-meeting-send-attendee-events',
      props: true,
      component: () => import('@/views/SendInviterEvents')
    },
    {
      path: '/create-meeting/send-attendee-events/:meetingId/:generateSlotsWith',
      name: 'create-meeting-send-attendee-events-with-generate-slots-with',
      props: true,
      component: () => import('@/views/SendInviterEvents')
    },
    {
      path: '/create-adjusted-meeting/:startDate/:duration',
      name: 'create-adjusted-meeting',
      props: true,
      component: () => import('@/views/CreateAdjustedMeeting')
    },
    {
      path: '/meeting/:meetingId/:inviteeEmail?',
      name: 'meeting-with-meetingId',
      props: true,
      component: () => import('@/views/Meeting')
    },
    {
      path: '/new-meeting/:meetingId/:inviteeEmail?',
      name: 'new-meeting',
      props: true,
      component: () => import('@/views/Meeting')
    },
    {
      path: '/inviter-meeting/:meetingId/:filter?',
      name: 'inviter-meeting',
      props: true,
      component: () => import('@/views/Meeting')
    },
    {
      path: '/invitation/:meetingId',
      name: 'invitation-with-meetingId',
      props: true,
      component: () => import('@/views/Invitation')
    },
    {
      path: '/invitation/lp/:meetingId',
      name: 'invitation-lp',
      props: true,
      component: () => import('@/views/InvitationLP')
    },
    {
      path: '/invitation/recommend-google-auth/:meetingId/:attendeeIndex?',
      name: 'invitation-recommend-google-auth',
      props: true,
      component: () => import('@/views/InvitationRecommendGoogleAuth')
    },
    {
      path: '/invitation/input-contact/:meetingId/:attendeeIndex?',
      name: 'invitation-input-contact',
      props: true,
      component: () => import('@/views/InvitationInputContact')
    },
    {
      path: '/invitation/:meetingId/:inviteeEmail',
      name: 'invitation-with-meetingId-inviteeEmail',
      props: true,
      component: () => import('@/views/Invitation')
    },
    {
      path: '/invitation/reschedule/:meetingId/:inviteeEmail',
      name: 'invitation-reschedule',
      props: true,
      component: () => import('@/views/Invitation')
    },
    {
      path: '/send-events/:meetingId/:inviteeEmail',
      name: 'send-events',
      props: true,
      component: () => import('@/views/SendEvents')
    },
    {
      path: '/send-events-complete/:meetingId/:inviteeEmail',
      name: 'send-events-complete',
      props: true,
      component: () => import('@/views/SendEventsComplete')
    },
    {
      path: '/invitation/send-attendee-events/:meetingId/:generateSlotsWith/:inviteeEmail',
      name: 'invitation-send-attendee-events-with-generate-slots-with',
      props: true,
      component: () => import('@/views/SendInviteeEvents')
    },
    {
      path: '/sign-in/:redirectPath?',
      name: 'sign-in',
      props: true,
      component: () => import('@/views/SignIn')
    },
    {
      path: '/sign-in/:invitedEmail',
      name: 'sign-in-with-inviteeEmail',
      props: true,
      component: () => import('@/views/SignIn')
    },
    {
      path: '/sign-up',
      name: 'sign-up',
      props: true,
      component: () => import('@/views/SignIn')
    },
    {
      path: '/sign-up/:invitedEmail',
      name: 'sign-up-with-inviteeEmail',
      props: true,
      component: () => import('@/views/SignIn')
    },
    {
      path: '/auth-callback-2',
      redirect: '/auth-callback/team-plan'
    },
    {
      path: '/auth-callback-team-plan',
      redirect: '/auth-callback/team-plan'
    },
    {
      path: '/auth-callback-professional-plan',
      redirect: '/auth-callback/professional-plan'
    },
    {
      path: '/auth-callback-team',
      redirect: '/auth-callback/team-plan'
    },
    {
      path: '/auth-callback-professional',
      redirect: '/auth-callback/professional-plan'
    },
    {
      path: '/auth-callback/zoom',
      name: 'auth-callback/zoom',
      component: () => import('@/views/zoom/AuthCallback')
    },
    {
      path: '/auth-callback/:planType?',
      name: 'auth-callback',
      props: true,
      component: () => import('@/views/AuthCallback')
    },
    // {
    //   path: '/auth-callback/:redirectPath?',
    //   name: 'auth-callback',
    //   props: true,
    //   component: () => import('@/views/AuthCallback')
    // },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/views/About')
    },
    {
      path: '/sign-out',
      name: 'sign-out',
      component: () => import('@/views/SignOut')
    },
    {
      path: '/account',
      name: 'account',
      props: true,
      component: () => import('@/views/Account')
    },
    {
      path: '/select-plan',
      name: 'select-plan',
      props: true,
      component: () => import('@/views/SelectPlan')
    },
    {
      path: '/downgrade-plan',
      name: 'downgrade-plan',
      props: true,
      component: () => import('@/views/DowngradePlan')
    },
    {
      path: '/register-credit-card/:planType?',
      name: 'register-credit-card',
      props: true,
      component: () => import('@/views/RegisterCreditCard')
    },
    {
      path: '/confirm-subscription',
      name: 'confirm-subscription',
      props: true,
      component: () => import('@/views/ConfirmSubscription')
    },
    {
      path: '/team/create',
      name: 'create-team',
      props: true,
      component: () => import('@/views/CreateTeam')
    },
    {
      path: '/team/update',
      name: 'update-team',
      props: true,
      component: () => import('@/views/CreateTeam')
    },
    {
      path: '/team',
      name: 'team-details',
      props: true,
      component: () => import('@/views/TeamDetails')
    }
  ]
})
