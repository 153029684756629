// eslint-disable-next-line no-unused-vars

export const state = () => ({
  planSettings: {
    planId: null,
    stripePaymentMethodId: null,
    cardHolderName: null,
    couponCode: null,
  }
})

export const mutations = {
  updatePlanSettings(state, value) {
    state.planSettings = {
      ...state.planSettings,
      ...value
    }
  },
  clearPlanSettings(state) {
    state.planSettings = {}
  },
}

export const actions = {
  // eslint-disable-next-line no-unused-vars
  test({ dispatch, commit }, test) {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve) => {
      commit('test', test)
    })
  }
}
