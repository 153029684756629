/**
 * 
 * @param {string[]} arr 
 * @returns {string[]}
 */
export const withoutDuplicates = (arr) => {
   const output = []

   for (const item of arr) {
     if (!output.includes(item)) {
      output.push(item)
     }
   }

   return output
}