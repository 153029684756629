import { withoutDuplicates } from '@/components/utils/array'
import firebase from 'firebase/app'
import firestore from '../components/utils/firebase'

/**
 * @interface BaseCollection
 * @template TModel
 */
class BaseCollection {
  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection() {
    return firestore.collection('__NONE__')
  }

  /**
   * @param {FirebaseFirestore.DocumentSnapshot<FirebaseFirestore.DocumentData>} doc
   * @returns {TModel}
   */
  getDataFromDocumentData(doc) {
    if (doc.exists) {
      return {
        ...doc.data(),
        id: doc.id,
        objectId: doc.id
      }
    } else {
      return null
    }
  }

  /**
   * @returns {Promise<TModel[]>}
   */
  all() {
    return this.collection.get().then((q) => q.docs.map(this.getDataFromDocumentData))
  }

  /**
   * @returns {Promise<string[]>}
   */
  allIds() {
    return this.collection.get().then((q) => q.docs.map((item) => item.id))
  }

  /**
   * @param {string} id
   * @returns {Promise<TModel>}
   */
  findById(id) {
    return this.collection.doc(id).get().then(this.getDataFromDocumentData)
  }

  findByIdWithSnapshot(id, listener) {
    return this.collection.doc(id).onSnapshot(listener)
  }

  /**
   * @param {string[]} ids
   * @returns {Promise<TModel[]>}
   */
  async findListByIds(ids) {
    const uniqueIds = withoutDuplicates(ids)

    const count = uniqueIds.length
    if (count === 0) {
      return []
    }

    const n = Math.ceil(count / 10)
    const list = []
    for (let i = 0; i < n; i++) {
      const subIds = uniqueIds.slice(i * 10, Math.min(count, (i + 1) * 10))

      const temp = await this.collection
        .where(firebase.firestore.FieldPath.documentId(), 'in', subIds)
        .get()
        .then((snap) => snap.docs.map(this.getDataFromDocumentData))
      list.push(...temp)
    }

    return list
  }

  /**
   * @param {TModel} obj
   * @returns {Promise<void>}
   */
  add(obj) {
    return this.collection.add(obj).then((d) => d)
  }

  /**
   * @param {string} id
   * @param {TModel} obj
   * @returns {Promise<void>}
   */
  set(id, obj) {
    return this.collection.doc(id).set(obj)
  }

  /**
   * @param {string} id
   * @param {TModel} obj
   * @returns {Promise<void>}
   */
  update(id, obj) {
    return this.collection.doc(id).update(obj, { merge: true })
  }

  /**
   * @param {string} id
   * @returns {Promise<void>}
   */
  delete(id) {
    return this.collection.doc(id).delete()
  }
}

/**
 * @typedef IUserModel
 * @property {string} id
 */

/**
 * @extends {BaseCollection<IUserModel>}
 */
class UserCollection extends BaseCollection {
  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection() {
    return firestore.collection('USER')
  }

  /**
   * @param {FirebaseFirestore.DocumentSnapshot<FirebaseFirestore.DocumentData>} doc
   * @returns {IUserModel}
   */
  getDataFromDocumentData(doc) {
    if (doc.exists) {
      return {
        ...doc.data(),
        uid: doc.id,
        id: doc.id
      }
    } else {
      return null
    }
  }

  /**
   * @returns {Promise<string[]>}
   */
  getUserByUid(uid) {
    return (
      this.collection
        .where('uid', '==', uid)
        .get()
        // .then(q => q.docs.map(item => item.data())[0])
        .then((q) => {
          return q.docs.map((item) => {
            return {
              id: item.id,
              ...item.data()
            }
          })[0]
        })
    )
  }

  /**
   *
   * @param {string} emails
   */
  async findListByEmails(emails) {
    const uniqueEmails = withoutDuplicates(emails)

    const count = uniqueEmails.length
    if (count === 0) {
      return []
    }

    const n = Math.ceil(count / 10)
    const list = []
    for (let i = 0; i < n; i++) {
      const subEmails = uniqueEmails.slice(i * 10, Math.min(count, (i + 1) * 10))

      const temp = await this.collection
        .where('email', 'in', subEmails)
        .get()
        .then((snap) => snap.docs.map(this.getDataFromDocumentData))
      list.push(...temp)
    }

    return list
  }

  getUserByUidWithSnapshot(uid, listener) {
    return this.collection.where('uid', '==', uid).onSnapshot(listener)
  }

  getUserByEmail(email) {
    return this.collection
      .where('email', '==', email)
      .get()
      .then((q) => {
        if (q.docs[0]) {
          return q.docs[0].data()
        } else {
          return null
        }
      })
  }

  updateUserInformation(uid, user) {
    return this.collection.doc(uid).update(user)
  }

  signUp(user, token) {
    if (!user.iconURL) {
      const defaultIconURL =
        'https://firebasestorage.googleapis.com/v0/b/fir-tmp-project.appspot.com/o/public%2Faccount.png?alt=media&token=4ad2981f-61ac-42d9-a5ed-45eda74077d0'
      user.iconURL = defaultIconURL
    }
    // if (!user.coverPhotoURL) {
    //   const defaultCoverPhotoURL = 'https://firebasestorage.googleapis.com/v0/b/fir-tmp-project.appspot.com/o/public%2Faccount.png?alt=media&token=4ad2981f-61ac-42d9-a5ed-45eda74077d0'
    //   user.coverPhotoURL = defaultCoverPhotoURL
    // }

    if (token) {
      this.collection.doc(user.uid).collection('SECRET').doc('google').set(token)
    }

    return this.collection.doc(user.uid).set({
      ...user,
      domain: user.email ? user.email.split('@').pop() : null
    })
  }

  // CALENDAR
  addCalendarSlot(uid, slot) {
    return this.collection.doc(uid).collection('CALENDAR').add(slot)
  }
  updateCalendarSlot(uid, slotId, slot) {
    return this.collection.doc(uid).collection('CALENDAR').doc(slotId).update(slot)
  }
  getCalendarSlots(uid) {
    return this.collection
      .doc(uid)
      .collection('CALENDAR')
      .get()
      .then((q) =>
        q.docs.map((d) => {
          return { id: d.id, ...d.data() }
        })
      )
  }

  // SECRET
  setGoogleToken(uid, token) {
    return this.collection.doc(uid).collection('SECRET').doc('google').set(token)
  }

  getGoogleToken(uid) {
    return this.collection
      .doc(uid)
      .collection('SECRET')
      .doc('google')
      .get()
      .then((d) => d.data())
  }

  getMicrosoftToken(uid) {
    return this.collection
      .doc(uid)
      .collection('SECRET')
      .doc('microsoft')
      .get()
      .then((d) => d.data())
  }

  getZoomToken(uid) {
    return this.collection
      .doc(uid)
      .collection('SECRET')
      .doc('zoom')
      .get()
      .then((d) => d.data())
  }

  // CALENDAR
  createCalendarEvent(uid, event) {
    return this.collection.doc(uid).collection('CALENDAR').add(event)
  }

  updateCalendarEvent(uid, id, params) {
    return this.collection.doc(uid).collection('CALENDAR').doc(id).update(params)
  }

  setCalendarEvent(uid, id, event) {
    return this.collection.doc(uid).collection('CALENDAR').doc(id).set(event)
  }

  getCalendarEvents(uid) {
    return this.collection
      .doc(uid)
      .collection('CALENDAR')
      .get()
      .then((q) =>
        q.docs.map((d) => {
          return { ...d.data(), id: d.id }
        })
      )
  }

  // CONTACTS
  getAllContacts(uid) {
    return this.collection
      .doc(uid)
      .collection('CONTACT')
      .orderBy('name')
      .get()
      .then((q) => q.docs.map((d) => d.data()))
  }

  addMemberToContact(uid, member) {
    return this.collection
      .doc(uid)
      .collection('CONTACT')
      .add(member)
      .then((d) => d.id)
  }
}

class GroupCollection extends BaseCollection {
  constructor(uid) {
    super()
    this._uid = uid
  }

  get collection() {
    return firestore.collection('USER').doc(this._uid).collection('GROUP')
  }

  getGroupByMeetingId(meetingId) {
    return this.collection
      .where('meetingId', '==', meetingId)
      .get()
      .then((q) => q.docs.map((d) => d.data())[0])
  }
}

/**
 * @typedef IMeetingModel
 * @property {string} id
 */

/**
 * @extends {BaseCollection<IMeetingModel>}
 */
class MeetingCollection extends BaseCollection {
  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection() {
    return firestore.collection('MEETING')
  }

  getMeetingInRealTime(id, listener) {
    return this.collection.doc(id).onSnapshot(listener)
  }

  getMeetingHistoriesInRealTime(id, listener) {
    return this.collection.doc(id).collection('HISTORY').onSnapshot(listener)
  }

  getJoiningMeetings(myEmail) {
    return this.collection
      .where('attendeeEmail', 'array-contains', myEmail)
      .orderBy('createdAt', 'desc')
      .get()
      .then((q) =>
        q.docs.map((d) => {
          let data = d.data()
          data.id = d.id
          return data
        })
      )
  }

  getJoiningMeetingsAsc(myEmail) {
    return this.collection
      .where('attendeeEmail', 'array-contains', myEmail)
      .orderBy('createdAt', 'asc')
      .get()
      .then((q) =>
        q.docs.map((d) => {
          let data = d.data()
          data.id = d.id
          return data
        })
      )
  }

  getJoiningMeetingsByStatus(myEmail, status) {
    return this.collection
      .where('attendeeEmail', 'array-contains', myEmail)
      .where('status', '==', status)
      .orderBy('createdAt', 'desc')
      .get()
      .then((q) =>
        q.docs.map((d) => {
          return { id: d.id, ...d.data() }
        })
      )
  }

  getInvitedMeetings(myEmail) {
    return this.collection
      .where('attendeeEmail', 'array-contains', myEmail)
      .orderBy('createdAt', 'desc')
      .get()
      .then((q) =>
        q.docs.map((d) => {
          let data = d.data()
          data.id = d.id
          return data
        })
      )
  }

  getInvitingMeetings(uid) {
    return this.collection
      .where('createdBy', '==', uid)
      .orderBy('createdAt', 'desc')
      .get()
      .then((q) =>
        q.docs.map((d) => {
          let data = d.data()
          data.id = d.id
          return data
        })
      )
  }

  setAttendeeEvents(meetingId, email, events) {
    return this.collection.doc(meetingId).collection('ATTENDEE_EVENTS').doc(email).set(events)
  }

  getAttendeeEventsByEmail(meetingId, email) {
    return this.collection
      .doc(meetingId)
      .collection('ATTENDEE_EVENTS')
      .where('email', '==', email)
      .get()
      .then((q) => q)
  }

  getAttendeeEventsByEmailRealTime(meetingId, email, listener) {
    return this.collection
      .doc(meetingId)
      .collection('ATTENDEE_EVENTS')
      .where('email', '==', email)
      .onSnapshot(listener)
  }

  getLatestAttendeeEvents(meetingId) {
    return this.collection
      .doc(meetingId)
      .collection('ATTENDEE_EVENTS')
      .orderBy('createdAt', 'desc')
      .limit(1)
      .get()
      .then((q) => q.docs.map((d) => d.data())[0])
  }

  saveGoogleCalendarEvent(meetingId, event) {
    return this.collection.doc(meetingId).update({
      googleCalendarEvent: event
    })
  }
}

/**
 * @typedef ITeamModel
 * @property {string} id
 * @property {string} name
 * @property {string} owner
 * @property {string[]} members
 * @property {string[]} membershipDomains
 * @property {string[]} invitedEmails
 * @property {string?} latestInvoiceId
 * @property {firebase.firestore.Timestamp?} latestPeriodStart
 * @property {firebase.firestore.Timestamp?} latestPeriodEnd
 * @property {boolean} active
 * @property {firebase.firestore.Timestamp} downgradedAt
 * @property {string} createdBy
 * @property {firebase.firestore.Timestamp} createdAt
 */

/**
 * @extends {BaseCollection<ITeamModel>}
 */
class TeamCollection extends BaseCollection {
  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection() {
    return firestore.collection('TEAM')
  }

  /**
   *
   * @param {string} ownerId
   * @returns {Promise<ITeamModel | null>}
   */
  async findActiveTeamByOwnerId(ownerId) {
    let list = await this.collection
      .where('owner', '==', ownerId)
      .where('active', '==', true)
      .limit(1)
      .get()
      .then((snap) => snap.docs.map(this.getDataFromDocumentData))
    return list.length > 0 ? list[0] : null
  }

  /**
   *
   * @param {string} ownerId
   * @returns {Promise<ITeamModel | null>}
   */
  async findAnyTeamByOwnerId(ownerId) {
    let list = await this.collection
      .where('owner', '==', ownerId)
      .limit(1)
      .get()
      .then((snap) => snap.docs.map(this.getDataFromDocumentData))
    return list.length > 0 ? list[0] : null
  }

  /**
   *
   * @param {string} memberId
   * @returns {Promise<ITeamModel | null>}
   */
  async findActiveTeamByMemberId(memberId) {
    let list = await this.collection
      .where('members', 'array-contains', memberId)
      .where('active', '==', true)
      .limit(1)
      .get()
      .then((snap) => snap.docs.map(this.getDataFromDocumentData))
    return list.length > 0 ? list[0] : null
  }
}

/**
 * @typedef IPendingSubscriptionModel
 * @property {string} id
 * @property {string} owner
 * @property {string} couponCode
 * @property {firebase.firestore.Timestamp} createdAt
 */

/**
 * @extends {BaseCollection<IPendingSubscriptionModel>}
 */
class PendingSubscriptionCollection extends BaseCollection {
  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection() {
    return firestore.collection('PENDING_SUBSCRIPTION')
  }
}

class SettingsCollection extends BaseCollection {
  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection() {
    return firestore.collection('SETTINGS')
  }
}

/**
 * The whole database
 */
const database = {
  userCollection: () => new UserCollection(),
  groupCollection: (uid) => new GroupCollection(uid),
  meetingCollection: () => new MeetingCollection(),
  teamCollection: () => new TeamCollection(),
  pendingSubscriptionCollection: () => new PendingSubscriptionCollection(),
  settingsCollection: () => new SettingsCollection()
}

const createDocId = () => {
  return firestore.collection('_').doc().id
}

export default { ...database, createDocId }
