import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import { api, config, domain } from './config'

const firebaseApp = firebase.initializeApp(config)

const firestore = firebaseApp.firestore()
// firestore.useEmulator('localhost', 8081)
const storage = firebaseApp.storage()
const auth = firebaseApp.auth()
const firebaseFunctions = firebase.app().functions('asia-northeast1')
// firebaseFunctions.useEmulator('localhost', 5001)

export default firestore
export { firebase, auth, storage, api, domain, firebaseFunctions }
