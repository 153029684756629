import database from '@/database'
import { getGoogleCalendarEvents } from '@/services/google'
import {
  setHours,
  setMinutes,
  addDays,
  addHours,
  addMinutes,
  isEqual,
  isBefore,
  isAfter,
  startOfDay,
  startOfHour,
  format
} from 'date-fns'
import { getMicrosoftCalendarEvents } from '@/services/microsoft'

export default {
  data() {
    return {
      defaultAvaliableSlotConditionSettings: {
        dateRange: {
          start: startOfHour(addHours(new Date(), 1)),
          end: addDays(new Date(), 365)
        },
        timeRange: {
          start: '9:00',
          end: '17:00'
        },
        duration: 60,
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        timeZone: 'Asia/Tokyo'
      }
    }
  },
  methods: {
    async getGoogleEvents(accessToken, uid) {
      // eslint-disable-next-line no-unused-vars
      const gEvents = await getGoogleCalendarEvents({ accessToken, uid })
      debugger
    },

    async getMsEvents() {
      const microsoftToken = await database.userCollection().getMicrosoftToken(this.uid)
      if (!microsoftToken) {
        return []
      }
      const microsoftEvents = await getMicrosoftCalendarEvents()
      let msEvents = []
      if (microsoftEvents && microsoftEvents.value) {
        msEvents = microsoftEvents.value.map((e) => {
          return {
            title: e.subject || ' ',
            start: e.start.dateTime ? new Date(e.start.dateTime) : new Date(e.start.date),
            end: e.end.dateTime ? new Date(e.end.dateTime) : new Date(e.end.date),
            isAllDayEvent: e.isAllDay,
            eventType: 'outlook'
          }
        })
      }
      return msEvents
    },

    // eslint-disable-next-line no-unused-vars
    async getAllEvents({ uid, email, accessToken }) {
      let [adjustedMeetings, googleEvents, microsoftEvents] = await Promise.all([
        database.meetingCollection().getJoiningMeetingsByStatus(email, 'adjusted'),
        getGoogleCalendarEvents({ accessToken, uid })
      ])

      console.log('msEvents', microsoftEvents)
      adjustedMeetings = adjustedMeetings.map((m) => {
        m.start = m.adjustedDate.start.toDate()
        m.end = m.adjustedDate.end.toDate()
        m.eventType = 'meeting'
        return m
      })

      let gcEvents = []
      let googleCalendarEvents = googleEvents.data.items
      if (googleCalendarEvents && googleCalendarEvents[0]) {
        gcEvents = googleCalendarEvents.map((e) => {
          return {
            title: e.summary ? e.summary : ' ',
            start: e.start.dateTime ? new Date(e.start.dateTime) : new Date(e.start.date),
            end: e.end.dateTime ? new Date(e.end.dateTime) : new Date(e.end.date),
            isAllDayEvent: e.start.date ? true : false,
            eventType: 'google'
          }
        })
      }

      const msEvents = await this.getMsEvents()
      return [...adjustedMeetings, ...gcEvents, ...msEvents]
    },
    // async getAllEvents({ uid, email, accessToken }) {
    //   console.log('getAllEvents start:', new Date())

    //   let adjustedMeetings = await database
    //     .meetingCollection()
    //     .getJoiningMeetingsByStatus(email, 'adjusted')
    //   adjustedMeetings = adjustedMeetings.map((m) => {
    //     m.start = m.adjustedDate.start.toDate()
    //     m.end = m.adjustedDate.end.toDate()
    //     m.eventType = 'meeting'
    //     return m
    //   })

    //   let myEvents = await database.userCollection().getCalendarEvents(uid)
    //   myEvents.map((e) => {
    //     e.start = e.start.toDate()
    //     e.end = e.end.toDate()
    //     e.eventType = 'my-event'
    //     return e
    //   })

    //   let gcEvents = []
    //   const {
    //     data: { items: googleCalendarEvents }
    //   } = await getGoogleCalendarEvents(accessToken)
    //   console.log('googleCalendarEvents:', googleCalendarEvents)
    //   if (googleCalendarEvents && googleCalendarEvents[0]) {
    //     gcEvents = googleCalendarEvents.map((e) => {
    //       return {
    //         title: e.summary ? e.summary : ' ',
    //         start: e.start.dateTime ? new Date(e.start.dateTime) : new Date(e.start.date),
    //         end: e.end.dateTime ? new Date(e.end.dateTime) : new Date(e.end.date),
    //         isAllDayEvent: e.start.date ? true : false,
    //         eventType: 'google'
    //       }
    //     })
    //   }

    //   console.log('getAllEvents end:', new Date())

    //   // TO DO: use promiseAll
    //   return [...adjustedMeetings, ...myEvents, ...gcEvents]
    // },
    generateAvailableSlots({ condition, events }) {
      const candidateSlots = this.generateSlotsByCondition(condition)
      const availableSlots = this.filterCandidateSlotsByEvents({
        candidateSlots,
        events
      })
      return availableSlots
    },
    generateSlotsByCondition({ dateRange, timeRange, duration, dayOfWeek }) {
      let result = []

      // 開始時間から１時間後の00分から調整開始
      let currentDate = startOfHour(addHours(dateRange.start, 1))
      let currentDay = startOfDay(currentDate)

      // ex timezoneの関係で10:00 ~ 17:00 が 01:00 ~ 10:00になるよ（9時間の時差がコンソール上でる）
      for (;;) {
        let timeRangeStart = setHours(currentDay, timeRange.start.split(':')[0])
        timeRangeStart = setMinutes(timeRangeStart, timeRange.start.split(':')[1])
        let timeRangeEnd = setHours(currentDay, timeRange.end.split(':')[0])
        timeRangeEnd = setMinutes(timeRangeEnd, timeRange.end.split(':')[1])
        const currentDayTimeRange = {
          start: timeRangeStart,
          end: timeRangeEnd
        }
        const slot = {
          start: currentDate,
          end: addMinutes(currentDate, duration)
        }

        // console.log('currentDayTimeRange', currentDayTimeRange)

        if (isAfter(slot.end, currentDayTimeRange.end)) {
          // 条件の時間より後の時間のスロットなら次の日へ
          // console.log('isAfter:slot', slot)
          currentDay = addDays(currentDay, 1)
          continue
        } else if (isBefore(slot.start, currentDayTimeRange.start)) {
          // 条件の時間より前の時間のスロットかどうか
          // console.log('isBefore:slot', slot)
          currentDate = addMinutes(currentDate, duration)
        } else {
          // 曜日が条件に合っていたら結果に追加
          if (dayOfWeek.includes(format(slot.start, 'EEEE'))) result.push(slot)
          currentDate = addMinutes(currentDate, duration)
        }
        const tommorowOfEndDate = startOfDay(addDays(dateRange.end, 1))
        if (isBefore(tommorowOfEndDate, currentDate)) break

        // currentDate = startOfDay(addDays(currentDate, 1))
        // if (isSameDay(currentDate, dateRange.end)) break
      }
      return result
    },
    filterCandidateSlotsByEvents({ candidateSlots, events }) {
      const eventsDate = events.map((event) => {
        return { start: new Date(event.start), end: new Date(event.end) }
      })
      const newCandidateEvents = candidateSlots.filter((slot) => {
        let isOverlapping = false
        for (let i = 0; i < eventsDate.length; i++) {
          // スロットと候補日のミーティングが同じ場合
          // if (events[i].start == slot.start
          //  && events[i].end == slot.end) isOverlapping = true
          // スロットの候補日の開始時間か終了時間が一緒の場合
          if (isEqual(eventsDate[i].start, slot.start) || isEqual(eventsDate[i].end, slot.end))
            isOverlapping = true
          // スロットの中にミーティングの時間が含まれる場合
          if (isAfter(eventsDate[i].start, slot.start) && isBefore(eventsDate[i].end, slot.end))
            isOverlapping = true
          // スロットのstartがミーティングのstartとendの間にある場合
          if (isAfter(slot.start, eventsDate[i].start) && isBefore(slot.start, eventsDate[i].end))
            isOverlapping = true
          // スロットのendがミーティングのstartとendの間にある場合
          if (isAfter(slot.end, eventsDate[i].start) && isBefore(slot.end, eventsDate[i].end))
            isOverlapping = true
          //  if (isOverlapping) console.log('excluded slots:', slot)
        }
        return !isOverlapping
      })
      // console.log('filterCandidateSlots', newCandidateEvents)
      return newCandidateEvents
    },
    combineConditionAND({ masterCondition, instanceCondition }) {
      let newStartDate, newEndDate
      if (isAfter(instanceCondition.dateRange.start, masterCondition.dateRange.start)) {
        newStartDate = instanceCondition.dateRange.start
      } else {
        newStartDate = masterCondition.dateRange.start
      }
      if (isBefore(instanceCondition.dateRange.end, masterCondition.dateRange.end)) {
        newEndDate = instanceCondition.dateRange.end
      } else {
        newEndDate = masterCondition.dateRange.end
      }
      const dateRange = { start: newStartDate, end: newEndDate }
      if (isAfter(dateRange.start, dateRange.end)) {
        console.log('Invalid Condition: dateRange.start is not before dateRange.end')
        return false
      }

      const dayOfWeek = instanceCondition.dayOfWeek.filter((d) => {
        return masterCondition.dayOfWeek.includes(d)
      })
      if (!dayOfWeek[0]) {
        console.log('Invalid Condition: no day of week')
        return false
      }

      // const duration = (instanceCondition.duration <= masterCondition.duration) ?
      //   instanceCondition.duration : masterCondition.duration
      const duration = instanceCondition.duration

      // timeRange
      const mStartTimeStrArr = masterCondition.timeRange.start.split(':')
      const mStartTimeMinute = Number(mStartTimeStrArr[0]) * 60 + Number(mStartTimeStrArr[1])
      const mStartTimeDate = addMinutes(startOfDay(new Date()), mStartTimeMinute)

      const mEndTimeStrArr = masterCondition.timeRange.end.split(':')
      const mEndTimeMinute = Number(mEndTimeStrArr[0]) * 60 + Number(mEndTimeStrArr[1])
      const mEndTimeDate = addMinutes(startOfDay(new Date()), mEndTimeMinute)

      const iStartTimeStrArr = instanceCondition.timeRange.start.split(':')
      const iStartTimeMinute = Number(iStartTimeStrArr[0]) * 60 + Number(iStartTimeStrArr[1])
      const iStartTimeDate = addMinutes(startOfDay(new Date()), iStartTimeMinute)

      const iEndTimeStrArr = instanceCondition.timeRange.end.split(':')
      const iEndTimeMinute = Number(iEndTimeStrArr[0]) * 60 + Number(iEndTimeStrArr[1])
      const iEndTimeDate = addMinutes(startOfDay(new Date()), iEndTimeMinute)

      const newStartTime = isAfter(iStartTimeDate, mStartTimeDate) ? iStartTimeDate : mStartTimeDate
      const newEndTime = isBefore(iEndTimeDate, mEndTimeDate) ? iEndTimeDate : mEndTimeDate

      const timeRange = {
        start: format(newStartTime, 'HH:mm'),
        end: format(newEndTime, 'HH:mm')
      }
      if (isAfter(newStartTime, newEndTime)) {
        console.log('Invalid Condition: newStartTime is not before newEndTime')
        return false
      }

      const newCondition = { dateRange, dayOfWeek, duration, timeRange }
      return newCondition
    },
    combineConditionOR({ masterCondition, instanceCondition }) {
      let newStartDate, newEndDate
      if (isBefore(instanceCondition.dateRange.start, masterCondition.dateRange.start)) {
        newStartDate = instanceCondition.dateRange.start
      } else {
        newStartDate = masterCondition.dateRange.start
      }
      if (isAfter(instanceCondition.dateRange.end, masterCondition.dateRange.end)) {
        newEndDate = instanceCondition.dateRange.end
      } else {
        newEndDate = masterCondition.dateRange.end
      }
      const dateRange = { start: newStartDate, end: newEndDate }
      if (isAfter(dateRange.start, dateRange.end)) {
        console.log('Invalid Condition: dateRange.start is not before dateRange.end')
        return false
      }

      let dayOfWeek = [...instanceCondition.dayOfWeek, ...masterCondition.dayOfWeek]
      dayOfWeek = Array.from(new Set(dayOfWeek))
      if (!dayOfWeek[0]) {
        console.log('Invalid Condition: no day of week')
        return false
      }

      // const duration = (instanceCondition.duration <= masterCondition.duration) ?
      //   instanceCondition.duration : masterCondition.duration
      const duration = instanceCondition.duration

      // timeRange
      const mStartTimeStrArr = masterCondition.timeRange.start.split(':')
      const mStartTimeMinute = Number(mStartTimeStrArr[0]) * 60 + Number(mStartTimeStrArr[1])
      const mStartTimeDate = addMinutes(startOfDay(new Date()), mStartTimeMinute)

      const mEndTimeStrArr = masterCondition.timeRange.end.split(':')
      const mEndTimeMinute = Number(mEndTimeStrArr[0]) * 60 + Number(mEndTimeStrArr[1])
      const mEndTimeDate = addMinutes(startOfDay(new Date()), mEndTimeMinute)

      const iStartTimeStrArr = instanceCondition.timeRange.start.split(':')
      const iStartTimeMinute = Number(iStartTimeStrArr[0]) * 60 + Number(iStartTimeStrArr[1])
      const iStartTimeDate = addMinutes(startOfDay(new Date()), iStartTimeMinute)

      const iEndTimeStrArr = instanceCondition.timeRange.end.split(':')
      const iEndTimeMinute = Number(iEndTimeStrArr[0]) * 60 + Number(iEndTimeStrArr[1])
      const iEndTimeDate = addMinutes(startOfDay(new Date()), iEndTimeMinute)

      const newStartTime = isBefore(iStartTimeDate, mStartTimeDate)
        ? iStartTimeDate
        : mStartTimeDate
      const newEndTime = isAfter(iEndTimeDate, mEndTimeDate) ? iEndTimeDate : mEndTimeDate

      const timeRange = {
        start: format(newStartTime, 'HH:mm'),
        end: format(newEndTime, 'HH:mm')
      }
      if (isAfter(newStartTime, newEndTime)) {
        console.log('Invalid Condition: newStartTime is not before newEndTime')
        return false
      }

      const newCondition = { dateRange, dayOfWeek, duration, timeRange }
      return newCondition
    },
    combineDateRangeCondition(range1, range2) {
      let newStartDate, newEndDate
      if (isAfter(range1.start, range2.start)) {
        newStartDate = range1.start
      } else {
        newStartDate = range2.start
      }
      if (isBefore(range1.end, range2.end)) {
        newEndDate = range1.end
      } else {
        newEndDate = range2.end
      }
      return { start: newStartDate, end: newEndDate }
    }
  }
}
