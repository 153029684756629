import { firebase } from '@/components/utils/firebase'
import database from '@/database'

export const state = () => ({
  accessToken: null,
  userDisplayName: null,
  userAuthEmail: null,
  isAuthenticating: true,
  isLoggedIn: false,
  isSigningOut: false,
  uid: null,
  subscriptionEnder: null,
  isAnonymous: null
})

export const mutations = {
  updateAccessToken(state, value) {
    state.accessToken = value
  },
  updateUid(state, value) {
    state.uid = value
  },
  updateUserDisplayName(state, value) {
    state.userDisplayName = value
  },
  updateIsAuthenticating(state, value) {
    state.isAuthenticating = value
  },
  updateIsLoggedIn(state, value) {
    state.isLoggedIn = value
  },
  updateIsSigningOut(state, value) {
    state.isSigningOut = value
  },
  updateAuthStatus(state, user) {
    const isLoggedIn = !!user
    const hasAuthEmail = !!user && !!user.email
    state.userDisplayName = isLoggedIn ? user.displayName : null
    state.uid = isLoggedIn ? user.uid : null
    state.userAuthEmail = hasAuthEmail ? user.email : null
    state.isAuthenticating = false
    state.isLoggedIn = isLoggedIn
    state.isSigningOut = false
    state.isAnonymous = user ? user.isAnonymous : null
  },
  resetState(state) {
    state.isLoggedIn = false
    state.isAuthenticating = true
    state.userAuthEmail = null
    state.isSigningOut = false
    state.userDisplayName = null
    state.isAnonymous = null
  },
  updateSubscriptionEnder(state, value) {
    state.subscriptionEnder = value
  }
}

export const actions = {
  // eslint-disable-next-line no-unused-vars
  setupAuthStateHandler({ dispatch, commit, state }) {
    if (!state.subscriptionEnder) {
      commit('planSettings/clearPlanSettings', {}, { root: true })

      const ender = firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          commit('updateAuthStatus', user)
          if (state.accessToken === null) {
            const tokens = await database.userCollection().getGoogleToken(user.uid)
            if (tokens) {
              commit('updateAccessToken', tokens.accessToken)
            }
          }
          dispatch('team/loadTeamByUid', user.uid, { root: true })
        } else {
          commit('resetState')
          commit('planSettings/clearPlanSettings', {}, { root: true })
          commit('team/clearTeam', {}, { root: true })
        }
      })
      commit('updateSubscriptionEnder', ender)
    }
  },
  endSubscription({ state, commit }) {
    if (state.subscriptionEnder) {
      state.subscriptionEnder()
      commit('updateSubscriptionEnder', null)
    }
  },
  async signOut({ commit, dispatch }) {
    commit('updateIsSigningOut', true)
    try {
      dispatch('endSubscription')
      await firebase.auth().signOut()
      commit('resetState')
    } catch (err) {
      console.error('error signing out of firebase', err)
    } finally {
      commit('updateIsSigningOut', false)
    }
  },
  // eslint-disable-next-line no-unused-vars
  signInAnonymously({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInAnonymously()
        .then(() => {
          // firebase.auth().currentUser.then((user) => {
          //   commit('updateAuthStatus', user)
          //   resolve(user)
          // })
          resolve(true)
        })
        .catch(function (error) {
          console.error('Login anonymously error: ', error)
          reject(error)
        })
    })
  }
}
