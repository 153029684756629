// eslint-disable-next-line no-unused-vars
import db, { firebase } from '@/components/utils/firebase'

export const state = () => ({
  test: null
})

export const mutations = {
  test(state, value) {
    state.test = value
  }
}

export const actions = {
  // eslint-disable-next-line no-unused-vars
  test({ dispatch, commit }, test) {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve) => {
      commit('test', test)
    })
  }
}
